/* Sass Document */
.flx-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}

.flx-container-reverse {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}

.flx-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

/* Global Mixins */
html {
  font-size: 10px !important;
}

body {
  font-size: 1.8rem !important;
  line-height: 1.5 !important;
}

.font-size-button.default {
  color: #fff !important;
  background-color: #003975 !important;
}

/*# sourceMappingURL=fontsdefault.css.map */